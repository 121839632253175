<template>
  <article class="pod-page" :class="[fullscreen && 'full-screen']">
    <header>
      <v-btn v-if="!fullscreen" class="btn-back btn-logo">
        <!-- <v-img class="logo" src="@/assets/Union.png" contain /> -->
      </v-btn>

      <v-btn v-if="fullscreen" icon @click="leave">
        <v-icon size="30">fas fa-times</v-icon>
      </v-btn>

      <div class="spacer"></div>

      <div class="center-header">
        <div class="search" v-show="changePodValue">
          <v-autocomplete
            v-model="searchSelected"
            :loading="searchLoading"
            :search-input.sync="searchValue"
            :items="searchItems"
            :menu-props="{ contentClass: 'search-list' }"
            flat
            label="Name or Email"
            no-filter
            solo
            dense
            @update:search-input="search"
          >
          </v-autocomplete>
          <div v-if="searchSelected" class="search-result">
            <span>{{ searchSelected }}</span>
            <button @click="joinPod(searchSelected)">Join</button>
          </div>
        </div>

        <div v-if="showTimer" class="count-down">
          <vue-countdown-timer
            :start-time="counterStart"
            :end-time="counterEnd"
            :interval="1000"
            label-position="begin"
            :hour-txt="':'"
            :minutes-txt="':'"
            :seconds-txt="''"
            @end_callback="endCallBack('event ended')"
          >
            <template slot="start-label">
              <div class="start-label">YOUR EVENT STARTS IN</div>
            </template>
            <template slot="countdown" slot-scope="scope">
              <div class="timer">
                <span>{{ scope.props.hours }}</span>
                <span>{{ scope.props.hourTxt }}</span>

                <span>{{ scope.props.minutes }}</span>
                <span>{{ scope.props.minutesTxt }}</span>

                <span>{{ scope.props.seconds }}</span>
              </div>
            </template>
          </vue-countdown-timer>
        </div>
      </div>

      <div class="spacer"></div>
    </header>

    <main>
      <div v-if="isAdmin" class="user-list">
        <div class="menu-template">
          <h3>
            <span class="v-icon mr-2">
              <img src="~@/assets/user-list.svg" />
            </span>
            User List
          </h3>
          <pod-guests
            :users="users"
            :list-view="true"
            @set-selected-user="setSelectedUser"
          ></pod-guests>
        </div>
        <div class="menu-template">
          <v-btn
            class="mb-2"
            block
            small
            outlined
            :disabled="!selectedUser"
            @click="disableMicrophone"
          >
            {{ this.mode.toUpperCase() }}</v-btn
          >
          <v-btn
            class="mb-2"
            block
            small
            outlined
            :disabled="!selectedUser"
            @click="closeSession"
          >
            KICK
          </v-btn>
        </div>
      </div>

      <pod-concert
        class="pod-concert"
        ref="concert"
        :project="project"
        :env="env"
        :is-admin="isAdmin"
        :music-volume="musicVolume"
        :change-pod-value="changePodValue"
        @connection-failed="connectionFailed"
        @change-map-view="changeMapView"
        @emergency-available="emergencyCanAvailable"
      />
      <div class="column-hints">
        <aside class="aside-menu-btn">
          <div @click="changeTutorial" class="show-hint-button">
            <div v-if="!showTutorial" class="show-scan-btn">

              <button type="button" class="btn-up v-btn v-btn--block v-btn--outlined theme--dark v-size--small btn-scan">
                              
                <span class="v-btn__content"> <v-img
                class="scan-icon"
                src="~@/assets/scan-icon.svg"
                contain
              /> SCAN TO START</span>
              </button>
            </div>
            <div v-else class="show-tutorial-btn">
              <v-img
                class="toggle-icon"
                src="~@/assets/close-hint-icon.svg"
                contain
              />
              <span class="hint-button-description">CLOSE<br />HINTS</span>
            </div>
          </div>
          
          <pod-menu
            class="aside-menu-pod-control"
            v-if="isShow && !showTutorial"
            :users="users"
            :fullscreen="fullscreen"
            :show-tutorial="showTutorial"
            :emergency-available="emergencyAvailable"
            :is-admin="isAdmin"
            :started="concertStarted"
            @fullscreen-toggle="fullscreenToggle"
            @change-camera="changeCamera"
            @change-pod="changePod"
            @mute="mute"
            @report="report"
            @bug-report="bugReport"
            @emergency-camera="emergencyCamera"
          />
        </aside>
        <pod-controls
          class="pods-control-setting-menu"
          v-if="isShow && !showTutorial"
          :fullscreen="fullscreen"
          :microphone-muted="microphoneMuted"
          :microphone-disabled="microphoneDisabled"
          :show-tutorial="showTutorial"
          :self-uid="uid"
          :music-volume="musicVolume"
          :crowd-volume="crowdVolume"
          :micro-volume="microVolume"
          @toggle-mute="toggleMute"
          @show-tutorial="showTutorial = !showTutorial"
          @change-crowd-volume="changeCroudeVolume"
          @change-micro-volume="changeMicroVolume"
          @change-volume="changeVolume"
        />
      </div>
      <div v-if="uid" class="text-chat">
        <pod-text-chat
          :self-id="uid"
          :self-user-id="currentUser.userId"
          :self-name="name"
          :users="users"
          :messages="messages"
          :channel-name="`pod-${$route.params.id}`"
          :admin-payload="{ pod: $route.params.id, uid: this.uid }"
          :micro-muted="microphoneMuted"
          :micro-disabled="microphoneDisabled"
          :is-admin="isAdmin"
          @update-micro-state="updateMicroState"
          @message-sent="duplicateMessageToGlobalChat"
        />
      </div>
      <div>
        <v-snackbar
          v-model="snackbar"
          :multi-line="multiline"
          :timeout="timeout"
          :centered="centered"
          :color="notificationColor"
        >
          {{ notification }}
          <template v-slot:action="{ attrs }">
            <v-btn
              text
              color="red"
              center
              v-bind="attrs"
              @click="closeSnackbar"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </main>
    <!-- <aside> -->
    <!-- v-if="reserved || isAdmin" -->
    <!-- <div class="aside-menu-pod-control"> -->

    <!-- </div> -->
    <!-- </aside> -->

    <pod-hints
      @close-hints="showTutorial = false"
      v-if="showTutorial"
      :show-tutorial="showTutorial"
      :is-admin="isAdmin"
    />
  </article>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { SET_HIDE_UI } from "@/store/ui";

import PodMenu from "./PodMenu.vue";
import PodConcert from "./PixelPodConcert";
import PodControls from "./PodControls";
import PodHints from "./PodHints.vue";
import PodTextChat from "./PodNewTextChat.vue";

import Agora from "agora-rtc-sdk-ng";
import client, {
  AppId,
  getRTCToken,
  defaultMicrophoneConfig,
} from "@/services/agora";
import { client as rtm, getRTMToken } from "@/services/chat";

import awsAuth from "@/cognito/aws-auth";
import loader from "@/config.loader";
import request from "@/plugins/request";
import agoraRequest from "@/services/agora-request";
import PodGuests from "@/components/pod/PodGuests";

let adminChannel;
let connection;

/* eslint-disable no-unused-vars */
export default {
  components: {
    PodGuests,
    PodMenu,
    PodConcert,
    PodControls,
    PodHints,
    PodTextChat,
  },

  audio: null,

  data() {
    return {
      name: ",",
      currentUser: awsAuth.getUserTokens(),
      isAdmin: awsAuth.isAdmin(),
      isAddMenu: false,
      uid: null,
      session: null,
      cols: 12,
      showTimer: true,
      users: [],
      countDownStart: new Date() * 1000,
      fullscreen: false,
      microphoneMuted: true,
      microphoneDisabled: false,
      accessToken: null,
      socket: null,
      showTutorial: false,
      sessionToken: null,
      podId: null,
      snackbar: false,
      centered: true,
      multiline: true,
      timeout: 4500,
      notification: null,
      notificationColor: "",
      isChatOpen: true,
      concertId: 9987,
      isShow: false,
      counterStart: Date.now(),
      counterEnd: Date.now() + 10e3,

      musicVolume: 40,
      crowdVolume: 60,
      microVolume: 80,

      searchValue: "",
      searchSelected: "",
      searchItems: [],
      searchLoading: false,

      selectedUser: null,
      mode: "mute",
      changePodValue: false,
      emergency: false,
      emergencyAvailable: false,

      messages: [],

      socketClosing: false,
      reserved: false,
      concertStarted: false,
    };
  },
  computed: {
    project() {
      return loader.getConfigValue("VUE_PW_PROJECT_ID"); //this.$route.query.project;
    },
    env() {
      return this.$route.query.env; //loader.getConfigValue('VUE_PW_ENV_ID')
    },

    ...mapState({
      user: (state) => state.auth.user,
    }),
  },

  async mounted() {
    this.hideWrapper(true);
    console.log("PixelPodPage > mounted");
    this.getReservedPod();

    // setTimeout(() => {
    this.isShow = true;
    // }, 10000);
  },
  beforeDestroy() {
    document.querySelectorAll(".menu-hint").forEach((item) => item.remove());
    document
      .querySelectorAll(".menu-controls-hint")
      .forEach((item) => item.remove());
    this.closeAudioChat();
    this.destroyComponent(this.podId);
  },
  destroyed() {},
  methods: {
    async getSnapshot() {
      console.log("started");
      request({
        method: "GET",
        url: "/concert/api/Nats/GetSnapshotByConcertId?concertId=9987",
      })
        .catch((e) => {
          console.error(e);
          this.concertStarted = false;
        })
        .then((res) => {
          const startTime = new Date(res.data.start);
          const currentTime = new Date();

          this.concertStarted = startTime <= currentTime;

          const differenceTime = startTime.getTime() - currentTime.getTime();

          if (differenceTime > 900000) {
            this.$router.push({ path: "/tickets/purchase", append: true });
          } else {
            this.setMicrophoneState();
            this.getConcertState("setup");
          }

          if (startTime > currentTime) {
            const timer = startTime.getTime() - currentTime.getTime() - 200;
            console.info(`timer: ${timer}`);
            setTimeout(() => {
              this.concertStarted = true;
              var currentDate = "[" + new Date().toUTCString() + "] ";
              console.info(currentDate + "concert runned");
            }, timer);
          }
        });
    },
    async getReservedPod() {
      request({
        method: "GET",
        url: `/show/api/v1/Pod/GetReservedPods`,
      })
        .catch((e) => {
          console.log(e);
          this.reserved = false;
        })
        .then((d) => {
          const data = d.data.find((item) => item.showId === this.concertId);
          let podId = data?.podId;
          this.reserved = podId != undefined;
        })
        .finally((f) => {
          this.setConcert();
        });
    },
    toggleChat(val) {
      this.isChatOpen = val;
    },
    endCallBack(x) {
      this.showTimer = false;
    },
    ...mapMutations("ui", {
      hideWrapper: SET_HIDE_UI,
    }),

    search() {
      if (this.searchValue) {
        this.searchLoading = true;
        request(
          {
            method: "GET",
            url: `/concert/api/v1/Scene/SearchUser?usernamePattern=${this.searchValue.substring(
              0,
              10
            )}`,
          },
          this.$store
        )
          .then((result) => {
            if (result.status == 200) {
              result.data.forEach((item) => {
                this.searchItems = [
                  {
                    text: `username: ${item.username}, pod: ${item.podId}, place: ${item.placeId}`,
                    enable: item.occupiedPlace < item.countPlace,
                  },
                ];
              });
            }
          })
          .catch((error) => {
            console.warn(error);
            this.searchItems = [];
          })
          .finally(() => {
            this.searchLoading = false;
          });
      }
    },

    toggleTutorial() {
      this.showTutorial = !this.showTutorial;
    },

    setMicrophoneState() {
      request(
        {
          method: "GET",
          url: `/concert/api/v1/Scene/GetUserState?userId=${this.currentUser.userId}`,
        },
        this.$store
      ).then((result) => {
        if (result.status == 200) {
          this.microphoneDisabled = result.data.isMuted;
        }
      });
    },

    async destroyComponent(pod) {
      await agoraRequest({
        method: "DELETE",
        url: `/username?uid=${this.uid}&pod=${pod}`,
        body: JSON.stringify({ username: this.name }),
      }).then((res) => {
        this.uid = null;
        console.warn(res.status);
      });

      this.users = [];
      await rtm.logout();
    },
    async back() {
      await this.closeAudioChat();
      this.$router.push("/shows");
    },

    async logout() {
      await this.closeAudioChat();
      this.$router.push("/auth");
    },

    async closeAudioChat() {
      this.hideWrapper(false);
      this.socketClosing = true;

      request(
        { method: "POST", url: `/concert/api/v1/Scene/Leave` },
        this.$store
      ).then((result) => {
        console.log(result);
      });
      if (connection) connection.close();
      if (this.audio) this.audio.setEnabled(false);
      await client.leave();
    },
    async kickUser() {
      await awsAuth.signOut();
      await this.logout();
    },
    async leave() {
      this.fullscreen = false;
    },
    changeTutorial() {
      this.showTutorial = !this.showTutorial;
      this.isAddMenu = this.showTutorial;
    },

    closeConcert() {
      if (!this.$route.matched.some(({ name }) => name === "end")) {
        this.closeAudioChat();
        this.destroyComponent(this.podId);
        this.$router.push({ path: "end", append: true });
      }
    },

    async closePod(userId, podId) {
      const prePodId = this.$route.params.id;
      if (
        this.currentUser.userId === userId &&
        this.$route.params.id != podId
      ) {
        this.$router.replace({ path: "", params: { id: `${podId}` } });
        this.podId = podId;

        this.send({ type: "user.change.pod.start", value: false });

        await this.destroyComponent(prePodId);
        await client.leave();
        //this.setup();
        this.setAudioChannel(this.podId);
      }
    },

    receiveMessage(payload) {
      console.warn("received a message", payload);
    },

    changeVolume(value) {
      this.setFocus();

      this.musicVolume = value;
      const volume = value === 0 ? 0.001 : value / 100;
      this.send({ type: "user.chat.volume", value: volume });
    },

    changeCroudeVolume(value) {
      this.crowdVolume = value;
      this.users.forEach((user) => {
        user.audio.setVolume(value);
      });
      this.setFocus();
    },

    changeMicroVolume(value) {
      this.setFocus();

      this.microVolume = value;
      if (!this.microphoneMuted) this.audio.setVolume(value);
    },

    changeCamera(value) {
      console.log("Changed camera: ", value);
      this.addLog(`${this.user.username} changed camera: ${value}`);
      this.sendLog();
      this.setFocus();
      this.send({ type: "user.camera.change", value });
    },

    changePod() {
      this.$refs.concert.changePod();

      this.setFocus();
    },

    closeSnackbar() {
      this.snackbar = false;
      this.setFocus();
    },

    connectionFailed() {
      this.snackbar = true;
      this.notificationColor = "amber darken-4";
      this.notification =
        "There are currently no available servers. Please, refresh this page and try again";
    },

    changeMapView(value) {
      this.changePodValue = value;
    },

    emergencyCanAvailable() {
      this.emergencyAvailable = true;
    },

    joinPod(value) {
      const parts = value.split(", ");
      const dict = {};

      for (const part of parts) {
        const [key, value] = part.split(": ");
        dict[key] = value;
      }

      const podId = parts[1].split(": ")[1];
      if (this.$route.params.id != podId) {
        const url = this.isAdmin
          ? `/concert/api/v1/Scene/SelectAdminPod?podId=${dict.pod}`
          : `/concert/api/v1/Scene/SelectPod?podId=${dict.pod}&placeNumber=${dict.place}&needSelectFreePlace=true`;
        request({ method: "POST", url: url }, this.$store)
          .then((result) => {
            if (result.status == 200) {
              this.closePod(this.currentUser.userId, podId);
            }
          })
          .catch((error) => {
            console.warn(error);
          })
          .finally(() => {
            this.searchValue = "";
          });
      }
      this.searchValue = "";
    },

    setSelectedUser(user) {
      this.selectedUser = user;
      this.mode = !user.muted ? "mute" : "unmute";
    },

    disableMicrophone() {
      const data = this.selectedUser;
      const disabled = data.muted;
      request(
        {
          method: "POST",
          url: `/concert/api/v1/Manager/${
            disabled ? "Unmute" : "Mute"
          }User?userId=${data.userId}&concertId=${this.concertId}`,
        },
        this.$store
      )
        .then((result) => {
          this.mode = data.muted ? "mute" : "unmute";
          this.mute(data.uid);
          this.addLog(`${data} new mode: ${data.muted ? "mute" : "unmute"}`);
          this.sendLog();
        })
        .catch((error) => {
          console.error(`ERROR ${error}`);
          this.addLog(`ERROR ${error}`);
          this.sendLog();
        });
    },

    closeSession() {
      let data = {
        username: this.selectedUser.name,
        userId: this.selectedUser.userId,
        message: "closeUserSession",
        userPoolId: loader.getConfigValue("VUE_COGNITO_USER_POOL_ID"),
      };

      request(
        {
          method: "POST",
          url: `/concert/api/v1/Manager/DisableUser`,
          body: JSON.stringify(data),
        },
        this.$store
      )
        .then((result) => {
          this.notificationColor = "red darken-4";
          this.notification = `${data.username} blocked from system!`;
        })
        .catch((error) => {
          console.error(`ERROR ${error}`);
        });
    },

    actionByAdmin(data) {
      if (this.currentUser.userId === data.user) {
        switch (data.type) {
          case "kick":
            this.snackbar = true;
            this.notificationColor = "red darken-4";
            this.notification = `Dear, ${this.name}, you are excluded from the show!`;
            //awsAuth.updateUserStatus(data.toKick, true);
            setTimeout(this.kickUser, 3000);
            break;
          case "mute":
            this.microphoneDisabled = data.value;
            this.microphoneMuted = true;
            this.audio.setVolume(0);
            this.toggleAgoraMute(this.microphoneMuted);
            this.snackbar = true;
            this.notificationColor =
              data.value === true ? "red darken-4" : "green darken-4";
            this.notification = `Dear, ${this.user.username}, your microphone ${
              data.value === true ? "blocked" : "unlocked"
            } by ADMIN!`;
            break;
        }
      } else {
        switch (data.type) {
          case "announcement":
            this.snackbar = true;
            this.notification = `V-SUPPORT: ${data.message}`;
            this.notificationColor = "red darken-4";

            this.messages = [
              {
                type: "system",
                author: "admin",
                isAdmin: true,
                imageUrl:
                  "https://avatars3.githubusercontent.com/u/37018832?s=200&v=4",
                data: { text: `${data.message}` },
              },
            ];
            break;
        }
      }
    },

    setFocus() {
      this.$nextTick(() => this.$refs.concert.setFocus());
    },

    send(message) {
      this.$nextTick(() => this.$refs.concert.sendMessage(message));
    },

    duplicateMessageToGlobalChat(message) {
      console.log(message, this.concertId);
      adminChannel.sendMessage({
        messageType: "TEXT",
        text: JSON.stringify({ ...message, concertId: this.concertId }),
      });
    },

    toggleMute() {
      this.setFocus();

      this.audio.setVolume(this.microphoneMuted ? this.microVolume : 0);
      this.microphoneMuted = !this.microphoneMuted;
      this.toggleAgoraMute(this.microphoneMuted);
    },

    toggleAgoraMute(muted) {
      const pod = this.podId || this.$route.params.id;

      agoraRequest({
        method: "POST",
        url: `/username/microphone?uid=${this.uid}&pod=${pod}`,
        body: JSON.stringify({ muted: muted }),
      }).then((res) => console.warn(res.status));
    },

    voiceChatOn(evt) {
      let self = this;
      var rtc = client;
      const remoteAudio = rtc.getRemoteAudioStats();
      for (let key in remoteAudio) {
        let currentUser = self.users.find((user) => Number(key) === user.uid);
        if (currentUser && remoteAudio[key]["receiveLevel"]) {
          self.send({
            type: "user.chat.speaking",
            userId: currentUser.userId,
            value: remoteAudio[key]["receiveLevel"] >= 100,
          });
        }
      }
      if (self.audio) {
        self.send({
          type: "user.chat.speaking",
          userId: self.currentUser.userId,
          value:
            self.audio.getVolumeLevel() > 0.1 &&
            self.audio.getVolumeLevel() < 1.3,
        });
      }
    },
    updateMicroState(data) {
      let user = this.users.find((user) => data.userId === user.uid);
      if (user) {
        user.microMuted = data.muted;
        const index = this.users.findIndex((el) => el.uid === user.uid);
        this.users.splice(index, 1, user);
      }
    },
    fullscreenToggle() {
      this.fullscreen = !this.fullscreen;
    },
    report(selected) {
      const user = this.users.find((user) => selected === user.uid);

      const payload = {
        type: "text",
        text: JSON.stringify({
          type: "REPORT",
          userId: user.userId,
          uid: selected,
          concertId: this.concertId,
          pod: this.$route.params.id,
        }),
      };
      this.addLog(`${user} reported by ${this.user}`);
      this.sendLog();

      adminChannel.sendMessage(payload);
    },
    bugReport() {
      this.setFocus();
      this.send({ type: "report.button.clicked" });
    },
    async emergencyCamera() {
      this.emergency = !this.emergency;
      //this.send({ type: 'user.camera.emergency', value: this.emergency });

      const formData = new FormData();
      formData.append("mode", this.emergency);

      await request(
        {
          method: "POST",
          url: `/concert/api/v1/Manager/SetEmergencyGlobalMode`,
          type: "multipart/form-data",
          body: formData,
        },
        this.$store
      );
    },
    mute(selected) {
      const user = this.users.find((user) => selected === user.uid);
      if (!user.audio) {
        return;
      }

      if (user.muted === false) {
        user.audio.stop();
        this.send({ type: "user.chat.mute", userId: user.userId, value: true });
      } else {
        this.send({
          type: "user.chat.mute",
          userId: user.userId,
          value: false,
        });
        user.audio.play();
      }

      user.muted = !user.muted;
      this.addLog(`${user} is ${user.muted ? "muted" : "unmuted"}`);
      this.sendLog();
    },
    async setConcert() {
      const concertId = 9987;
      let podId = this.podId || this.$route.params.id;
      let needSelected = !this.isAdmin && this.reserved;
      request(
        {
          method: "POST",
          url: `/concert/api/v1/Scene/Join?needSelectPod=${needSelected}&concertId=${concertId}`,
        },
        this.$store
      )
        .then((result) => {
          if (result.status == 200) {
            if (result.data[0].pod && podId != result.data[0].pod.id)
              this.$router.replace({
                path: "",
                params: { id: `${result.data[0].pod.id}` },
              });

            this.setSessionToken();
            if (this.reserved || this.isAdmin) {
              this.getSnapshot();
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.notificationColor = "red darken-4";
              this.notification = `User unathorized!`;
              this.closeAudioChat();
              awsAuth.signOut();
              this.$router.push("/auth");
            }
          } else console.error(`ERROR ${error}`);
        });
    },
    async setSessionToken() {
      request(
        { method: "POST", url: "/concert/api/v1/Channel/CreateToken" },
        this.$store
      )
        .then((result) => {
          if (result.status == 200) {
            this.sessionToken = result.data.token;
            this.setSocket();
          }
        })
        .catch((error) => {
          console.error(`ERROR ${error}`);
        });
    },
    async getConcertState(command) {
      request(
        { method: "GET", url: `/concert/api/v1/Scene/GetConcertState` },
        this.$store
      )
        .then((result) => {
          if (result.data.state === "Active") {
            this[`${command}`]();
          } else {
            this.closeConcert();
          }
        })
        .catch((error) => {
          console.error(`ERROR ${error}`);
        });
    },
    async setSocket() {
      const baseUrl = loader.getConfigValue("VUE_VITAMOTUS_SOCKET_API");
      connection = new WebSocket(
        `${baseUrl.replace(
          "http",
          "ws"
        )}/api/v1/Channel/AcceptChannel?sessionId=${this.sessionToken}&type=web`
      );

      connection.onopen = (event) => {
        const type = event.type;
      };

      connection.onmessage = (event) => {
        if (event.data) {
          this.command(JSON.parse(event.data));
        }
      };
      connection.onclose = (event) => {
        if (!this.socketClosing) {
          this.getConcertState("setSocket");
        }
      };

      connection.onerror = (event) => {
        console.warn(event.error);
      };
    },

    command(data) {
      console.log("Command arrived: ", data);
      switch (data.Type) {
        case "user.drop":
          if (data.Reason === "ban") {
            this.actionByAdmin({
              type: "kick",
              value: true,
              user: data.UserId,
            });
          }

          break;
        case "user.mute":
          this.actionByAdmin({ type: "mute", value: true, user: data.UserId });
          break;
        case "announcement":
          this.actionByAdmin({ type: "announcement", message: data.Message });
          break;
        case "user.unmute":
          this.actionByAdmin({ type: "mute", value: false, user: data.UserId });
          break;
        case "concert.finish":
          this.closeConcert();
          break;
        case "user.admin.selectPod":
          this.closePod(data.UserId, data.PodId);
          break;
        case "user.change.pod":
          this.closePod(data.UserId, data.Pod.Id);
          break;
      }
    },

    async createMicrophone() {
      const audio = await Agora.createMicrophoneAudioTrack(
        defaultMicrophoneConfig
      );
      return audio;
    },

    async setAudioChannel(pod) {
      const username = (await awsAuth.userData()).data.username;
      this.podId = this.podId || this.$route.params.id;
      this.searchItems = [];
      this.changePodValue = false;

      const RTCToken = await getRTCToken(pod);
      const uid = await client.join(AppId, `pod-${pod}`, RTCToken);

      await agoraRequest({
        method: "POST",
        url: `/username?uid=${uid}&pod=${pod}`,
        body: JSON.stringify({
          username: this.user.username,
          id: awsAuth.getUserTokens().userId,
        }),
      });

      const RTMToken = await getRTMToken(uid.toString());
      await rtm.login({ uid: uid.toString(), token: RTMToken });

      adminChannel = rtm.createChannel("admin-global-chat");
      await adminChannel.join();

      const audio = await this.createMicrophone(); //await Agora.createMicrophoneAudioTrack();
      let self = this;
      await client.publish([audio]);

      audio.setVolume(0);
      self.audio = audio;
      self.microphoneMuted = true;
      self.uid = uid;
      self.name = username;
      client.enableAudioVolumeIndicator();
      return uid;
    },

    async setup() {
      const sign = await awsAuth.signCheck();
      if (sign) {
        this.accessToken = this.currentUser.accessToken;
        const pod = this.podId || this.$route.params.id;
        this.podId = pod;

        client.on("user-joined", async (user) => {
          let username = null;
          let muted = null;
          let userId = null;
          await agoraRequest(
            {
              method: "GET",
              url: `/username?uid=${user.uid}&pod=${this.podId}`,
            },
            this.$store
          )
            //.then(res => res.json())
            .then((json) => {
              if (json) {
                username = json.data.username;
                muted = json.data.muted;
                userId = json.data.id;
              }
            })
            .catch((error) => {
              console.warn(error.message);
            });
          if (username) {
            const profileImage =
              "https://lh3.googleusercontent.com/-G1d4-a7d_TY/AAAAAAAAAAI/AAAAAAAAAAA/AAKWJJPez_wX5UCJztzEUeCxOd7HBK7-jA.CMID/s83-c/photo.jpg";
            if (!this.users.find((item) => item.id === user.uid))
              this.users.push({
                ...user,
                id: user.uid,
                userId: userId,
                name: username,
                imageUrl: profileImage,
                audio: null,
                muted: false,
                microMuted: muted,
              });
          }
        });

        client.on("user-left", (user) => {
          this.users = this.users.filter((u) => u.uid !== user.uid);
        });

        client.on("user-published", async (user, media) => {
          // Initiate the subscription
          await client.subscribe(user, media);

          // If the subscribed track is an audio track
          setTimeout(() => {
            if (media === "audio") {
              const audioTrack = user.audioTrack;
              // Play the audio
              audioTrack.play();
            }

            const saved = this.users.find((u) => u.uid === user.uid) || {};
            let disabled = Object.entries(saved).length === 0;
            if (!disabled) {
              saved[media] = user[`${media}Track`];
              saved.muted = false;
            }
          }, 2500);
        });

        client.enableAudioVolumeIndicator();

        client.on("audio-stats", (stats) => {
          console.log(`stat: ${stats}`);
        });

        client.on("volume-indicator", () => this.voiceChatOn());

        client.on("user-unpublished", async (user, media) => {
          await client.unsubscribe(user, media);
        });

        /*const RTCToken = await getRTCToken(pod);
        const uid = await client.join(AppId, `pod-${pod}`, RTCToken);
        const baseUrl = loader.getConfigValue('VUE_AGORA_API_URL');
        await fetch(`${baseUrl}/username?uid=${uid}&pod=${pod}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({username: this.user.username})
        });*/
        const uid = await this.setAudioChannel(pod);
        if (this.socketClosing) {
          this.closeAudioChat();
          this.destroyComponent(pod);
        }

        //console.log(uid);
        /*const RTMToken = await getRTMToken(uid.toString());
        await rtm.login({uid: uid.toString(), token: RTMToken});

        rtm.on("MessageFromPeer", async (message) => {
          const data = JSON.parse(message.text);
          if (pod === data.pod) {
            switch (data.text) {
              case 'kick':
                this.snackbar = true;
                this.notificationColor = 'red darken-4';
                this.notification = `Dear, ${data.toKick}, you are excluded from the show!`;
                //awsAuth.updateUserStatus(data.toKick, true);
                setTimeout(this.kickUser, 3000);
                break;
              case 'mute':
                this.microphoneDisabled = data.value;
                this.microphoneMuted = true;
                this.audio.setVolume(0);
                this.toggleAgoraMute(this.microphoneMuted);
                this.snackbar = true;
                this.notificationColor = data.value === true ? 'red darken-4' : 'green darken-4'
                this.notification = `Dear, ${data.toMute}, your microphone ${data.value === true ? 'blocked' : 'unlocked'} by ADMIN!`;
                break;
            }
          }
        })*/

        /*rtm.on("MessageFromPeer", async (message) => {
          const data = JSON.parse(message.text);
          this.snackbar = true;
          this.notification = `Dear, ${data.toKick}, you are excluded from the show!`;
          setTimeout(this.kickUser, 3000);
        })*/
      }
    },
    ...mapMutations({
      addLog: "log/addLog",
    }),
    ...mapActions({
      sendLog: "log/sendLog",
    }),
  },
};
/* eslint-enable no-unused-vars */
</script>

<style lang="scss" scoped>
.spacer {
  flex-grow: 2;
}



.center-header {
  display: flex;
  flex-flow: column nowrap;

  flex-grow: 1;

  justify-content: center;
  align-items: center;

  & > * {
    margin: 0.5rem;
  }
}

.full-screen {
  background: rgba($color: #333, $alpha: 0.1)
    linear-gradient(#fff 50%, #000 100%) center;
}

.pod-page {
  position: relative;
  z-index: 1;
  height: 100vh;
  // overflow-y: hidden;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  background: rgba($color: #333, $alpha: 0.1)
    linear-gradient(#fff 50%, #000 100%) center;
  background-size: cover;

  .btn-back {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .pod-concert {
    position: absolute;
    height: 100vh;
    width: 100vw;
  }
  main {
    height: 100vh;
    justify-content: space-around;

    .user-list {
      z-index: 10;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 0.75em;
      position: absolute;
      right: 3%;
      min-width: 214px;
      top: 72%;
      transform: translateY(-72%);
      border: 2px solid white;

      .menu-template {
        h3 {
          font-family: Oswald;
          margin-bottom: 20px;
          text-align: center;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 12px;
          line-height: 23px;
          /* identical to box height, or 192% */
          color: #ffffff;
        }
      }
      button {
        background-color: rgba(255, 255, 255, 0.3);
      }

      .v-btn::v-deep .v-btn__content {
        font-weight: bold !important;
        font-size: 10px !important;
        line-height: 15px;
      }
    }
  }

  header {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    .count-down {
      background: rgba(0, 0, 0, 0.543271);
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 12px;
      min-width: 424px;
      text-align: center;
      padding: 10px 80px;
      z-index: 1 !important;

      .start-label {
        font-weight: bold;
        padding-bottom: 15px;
        font-size: 14px;
        line-height: 23px;
        text-align: center;
        letter-spacing: 4px;
        color: #ffffff;
      }

      .timer {
        &::v-deep span {
          font-weight: 300;
          font-size: 40px;
          line-height: 30px;
          letter-spacing: 10.8px;
          color: #ffffff;
        }
      }
    }

    .search {
      left: 50%;
      top: 30px;
      width: 90%;
      max-width: 500px;

      &::v-deep {
        .v-select--is-menu-active {
          .v-input__slot {
            border-radius: 20px 20px 0 0;
            border-bottom: none;

            input {
              border-bottom: 1px solid rgba(216, 216, 216, 0.3);
            }
          }

          & + .search-result {
            display: none !important;
          }
        }

        .v-input--is-label-active + .search-result {
          display: flex;
        }

        .v-text-field__details {
          display: none;
        }

        .v-input__control {
          min-height: 40px;
        }

        .v-input__slot {
          background: rgba(255, 255, 255, 0.20807);
          border: 1px solid #ffffff;
          box-sizing: border-box;
          border-radius: 20px;

          .v-input__append-inner {
            display: none;
          }

          .v-select__slot {
            & > label {
              font-size: 14px;
              line-height: 17px;
              letter-spacing: 1px;
              font-weight: 300;
              color: #ffffff;
              padding-left: 32px;
            }

            & > input {
              font-size: 14px;
              line-height: 40px;
              letter-spacing: 1px;
              color: #ffffff;
              background: url('data:image/svg+xml,<svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.2479 12.1971L21.5287 17.6409C22.0984 18.1347 22.161 19.0063 21.6671 19.5761L21.443 19.835C20.9491 20.4048 20.0775 20.4673 19.5078 19.9734L13.2734 14.5697" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M0 8.173C0 3.65967 3.65876 0 8.173 0C12.6863 0 16.346 3.65876 16.346 8.173C16.346 12.6872 12.6872 16.346 8.173 16.346C3.65876 16.346 0 12.6872 0 8.173ZM8.27656 14.1456C11.5755 14.1456 14.2491 11.4719 14.2491 8.173C14.2491 4.87408 11.5755 2.20042 8.27656 2.20042C4.97764 2.20042 2.30399 4.87408 2.30399 8.173C2.30399 11.4719 4.97764 14.1456 8.27656 14.1456Z" fill="white"/></svg>')
                no-repeat 0 center;
              padding-left: 32px;
              height: 40px;
              box-sizing: border-box;
              max-height: unset;
            }
          }
        }
      }
    }
  }
}

.search-list {
  box-shadow: none;

  .v-list {
    background: rgba(255, 255, 255, 0.20807);
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 0 0 20px 20px;
    margin-top: -2px;
    border-top: none;
    box-shadow: none;

    &::v-deep .v-list-item__content {
      background: url('data:image/svg+xml,<svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M5.00021 6.54599C6.59981 6.54599 7.90109 4.99408 7.90109 3.0871C7.90109 1.26929 6.70793 0 5.00021 0C3.29249 0 2.09932 1.26923 2.09932 3.0871C2.09932 4.99405 3.4001 6.54599 5.00021 6.54599ZM5.00021 0.965588C6.15749 0.965588 6.93502 1.81793 6.93502 3.08662C6.93502 4.46101 6.06721 5.57929 5.00021 5.57929C3.9332 5.57929 3.06539 4.46098 3.06539 3.08662C3.06477 1.81863 3.84289 0.965588 5.00021 0.965588ZM5.29649 6.86759H4.70269C1.88953 6.86759 0 8.49316 0 10.9116C0 11.1782 0.216489 11.3946 0.483079 11.3946C0.749669 11.3946 0.966159 11.1788 0.966159 10.9116C0.966159 8.63963 2.97888 7.83365 4.70279 7.83365H5.29658C7.02048 7.83365 9.03384 8.63959 9.03384 10.9116C9.03384 11.1782 9.24971 11.3946 9.51692 11.3946C9.78351 11.3946 10 11.1788 10 10.9116C10 8.49316 8.11031 6.86759 5.29668 6.86759H5.29649Z" fill="white"/></svg>')
        no-repeat 0 center;
      padding-left: 25px;
      font-size: 15px;
      line-height: 26px;
      letter-spacing: 1px;
      color: #ffffff;

      & * {
        font-size: inherit;
        line-height: inherit;
      }
    }
  }
}

.search-result {
  display: none;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.543271)
    url('data:image/svg+xml,<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.00029 9.16438C9.23973 9.16438 11.0615 6.99171 11.0615 4.32193C11.0615 1.77701 9.3911 0 7.00029 0C4.60948 0 2.93905 1.77692 2.93905 4.32193C2.93905 6.99166 4.76014 9.16438 7.00029 9.16438ZM7.00029 1.35182C8.62048 1.35182 9.70903 2.5451 9.70903 4.32127C9.70903 6.24541 8.4941 7.81101 7.00029 7.81101C5.50648 7.81101 4.29154 6.24537 4.29154 4.32127C4.29067 2.54608 5.38005 1.35182 7.00029 1.35182ZM7.41508 9.61462H6.58377C2.64535 9.61462 0 11.8904 0 15.2762C0 15.6494 0.303085 15.9525 0.676311 15.9525C1.04954 15.9525 1.35262 15.6503 1.35262 15.2762C1.35262 12.0955 4.17044 10.9671 6.5839 10.9671H7.41521C9.82868 10.9671 12.6474 12.0954 12.6474 15.2762C12.6474 15.6494 12.9496 15.9525 13.3237 15.9525C13.6969 15.9525 14 15.6503 14 15.2762C14 11.8904 11.3544 9.61462 7.41535 9.61462H7.41508Z" fill="white"/></svg>')
    no-repeat 17px center;
  border: 1px solid #ffffff;
  border-radius: 20px;
  padding: 7px 7px 7px 40px;
  margin-top: 8px;

  & > button {
    background: #ff3c11;
    border-radius: 15px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #ffffff;
    width: 77px;
    height: 26px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

html #app .pod-page header {
  padding: 1rem;
  position: absolute;
  z-index: 110;

  & .btn-logo {
    min-width: unset;
    margin: 0.5rem 0 0 3.2rem;
    .logo {
      width: 50px;
    }
  }
}

.aside-menu-pod-control {
  z-index: 110;
  // top: 0;
  padding-top: 6px;
  // position: absolute;
  // height: 80vh;
}
.pods-control-setting-menu {
  z-index: 110;
  // top: 0;
}
.column-hints {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}
.aside-menu-btn {
  margin-left: 50px;
  // margin-top: 10px;
  .show-hint-button {
    display: flex;
    flex-direction: column;
    z-index: 190;
  }

}
.hint-button-description {
  width: 80px;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  font-family: Oswald;
  font-style: normal;
  color: #ffffff;
  text-align: center;
  letter-spacing: -1.44px;
  // padding-top: 4%;
  z-index: 110;
}

.text-chat {
  position: absolute;
  right: 3%;
  bottom: 12%;
}

.remote-video {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;

  place-content: stretch;
  place-items: stretch;

  width: calc(100% - 80px);
  height: 100%;

  margin-left: 80px;

  & > * {
    max-width: 40%;
    max-height: 50%;

    min-width: 250px;
    min-height: 180px;

    padding: 1rem;
  }
}
.show-tutorial-btn {
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  width: 70px;
  align-items: center;
  justify-content: center;
  .toggle-icon {
    width: 80%;
    z-index: 110;
  }
}
.show-scan-btn {
  margin-top: 70px;
  margin-bottom: 3px;
  width: 194px;
  min-width: 194px;
  max-width: 194px;
  align-items: center;
  justify-content: center;
  .scan-icon {
    width: 1.2rem!important;
    display: inline-block;
    flex: none;
    margin-right: 0.5rem;
  }
  .btn-scan{
    background-color: rgba(0, 0, 0, 0.5);
    border: 2px solid white;
    border-radius: 10px;
    height: 35px!important;
      span{
            font-size: 0.7rem!important;
      }    
  }

}

@media screen and (min-width: 1920px) {
  html #app .pod-page header {
    & .btn-logo {
      .logo {
        width: 80px;
      }
    }
  }


  .show-hint-button {
    top: 90px;
  }
  .show-tutorial-btn {
    margin-top: 100px;
    width: 100px;

    .toggle-icon {
      width: 90%;
      z-index: 110;
    }
  }
  .show-scan-btn {
    margin-top: 100px;
    width: 100px;
    width:274px;
    max-width:274px;
    min-width:274px;
    .toggle-icon {
      width: 90%;
      z-index: 110;
    }
    .btn-scan{
      height:40px!important;
        span{
          font-size:15px!important;
        }      
    }

  }

  .hint-button-description {
    width: 100px;
    font-weight: 500;
    font-size: 25px;
  }
  .aside-menu-pod-control {
    top: 0;
  }
}
</style>
