<template>
  <article class="hints">
    <div class="column-hints">
      <div class="row-hints">
        <aside>
          <pod-menu
            class="pod-menu-hints"
            :users="hintMenuUsers"
            :is-admin="isAdmin"
            :show-tutorial="showTutorial"
          />
        </aside>

        <div class="hints-qr-container" ref="hintsContainer">
          <div class="qr-box">
            <div class="qr-image">
              <div class="qr-frame">
                <canvas id="qrCode" class="tutor-canvas"></canvas>
              </div>
            </div>
            <div class="qr-description-container">
              <div class="qr-item">
                <p class="qr-description">1. DOWNLOAD THE APP</p>
                <p class="qr-details">OPEN YOUR PHONE’S CAMERA AND SCAN!</p>
              </div>
              <div class="qr-item qr-item-middle">
                <p class="qr-description">2. OPEN THE APP</p>
              </div>
              <div class="qr-item">
                <p class="qr-description">3. BROADCAST!</p>
                <v-img src="~@/assets/stream-icon.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <pod-controls
        class="pods-control-setting-menu"
        :fullscreen="fullscreen"
        :show-tutorial="showTutorial"
        @show-tutorial="showTutorial = !showTutorial"
      />
    </div>
  </article>
</template>

<script>
import QRCode from "qrcode";
import PodMenu from "./PodMenu.vue";
import PodControls from "./PodControls";

export default {
  name: "PodHints.vue",
  components: {
    PodMenu,
    PodControls,
  },
  props: {
    isAdmin: { type: Boolean, default: false },
    showTutorial: { type: Boolean, default: true },
    fullscreen: { type: Boolean, default: true },
  },
  data() {
    return {
      hintMenuUsers: [
        {
          name: "John",
          id: 99911111,
          muted: false,
          uid: 99911111,
        },
        {
          name: "Michael",
          id: 99911112,
          muted: false,
          uid: 99911112,
        },
        {
          name: "Lara",
          id: 99911113,
          muted: false,
          uid: 99911113,
        },
        {
          name: "Peter",
          id: 99911114,
          muted: false,
          uid: 99911114,
        },
      ],
    };
  },
  methods: {
    scaleContainer() {
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      if (width < 1100) {
        this.$refs.hintsContainer.style.zoom = "0.6";
      }
      if (height < 720) {
        this.$refs.hintsContainer.style.zoom = "0.6";
      }
    },
  },
  mounted() {
    // window.addEventListener("resize", this.scaleContainer());
    let canvas = document.getElementById("qrCode");
    if (canvas == null) return;
    let link = localStorage.getItem("dynamic-link");

    QRCode.toCanvas(
      canvas,
      link,
      {
        width: 180,
        margin: 2,
      },
      function (error) {
        if (error) console.error(error);
      }
    );
  },
};
</script>

<style scoped lang="scss">
.hints {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 101;
  
  background-color: rgb(58, 57, 57);
}

.row-hints {
  display: flex;
  position: relative;
  padding-top: 115px;
  padding-left: 50px;
}
.column-hints {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}
.pod-menu-hints {
  margin-top: 57px;
  max-height: 90%;
}
.pods-control-setting-menu {
  position: relative;
  width: 100vw;
}
.hints-qr-container {
  position: relative;
  width: 400px;
  height: 95%;
  box-sizing: border-box;
  border-radius: 7px;
  border: 1px solid #ffffff;
  background-color: rgba(0, 0, 0, 0.54);
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  display: flex;
  align-items: center;
}

.qr-box {
  text-align: center;
  justify-content: flex-start;
  align-items: center;

  .qr-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .qr-frame {
    /*border: solid 1px #ffffff;*/
    /*border-radius: 7px;*/
  }

  .tutor-canvas {
    border-radius: 10px;
    width: 170px;
    height: 170px;
  }

  .qr-item {
    padding-top: 15px;

    .v-image {
      margin-top: 20px;
      width: 50px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .qr-item-middle {
    margin-top: 11px;
    margin-bottom: 11px;
  }

  .qr-description {
    font-family: Oswald;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.925714px;
    margin-bottom: 0;
  }

  .qr-details {
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;

    text-align: center;
    letter-spacing: 0.777778px;

    margin-bottom: 0;
  }
}
.pod-menu {
  z-index: 110;
}

@media screen and (min-width: 1420px) { 
}
@media screen and (min-width: 1600px) {
  .hints-qr-container {
    width: 450px;
    height: 100%;
  }
  .qr-box {
    .tutor-canvas {
      width: 210px;
      height: 210px;
    }
    .qr-item {
      padding-top: 25px;
      .v-image {
        // margin-top: 20px;
        width: 60px;
      }
    }
    .qr-item-middle {
      margin-top: 12px;
      margin-bottom: 12px;
    }
    .qr-description {
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.925714px;
      margin-bottom: 0;
    }
    .qr-details {
      font-size: 14px;
    }
  }
}

@media screen and (min-width: 1920px) {
  .row-hints {
    display: flex;
    position: relative;
    padding-top: 193px;
  }
  .hints-qr-container {
    width: 550px;
    height: 100%;
    
  }
  .qr-box {
    .tutor-canvas {
      width: 250px;
      height: 250px;
    }
    .qr-item {
      padding-top: 25px;
      .v-image {
        margin-top: 20px;
        width: 80px;
      }
    }
    .qr-item-middle {
      margin-top: 14px;
      margin-bottom: 14px;
    }
    .qr-description {
      font-size: 26px;
      line-height: 40px;
    }
    .qr-details {
      font-size: 18px;
    }
  }
}
</style>
